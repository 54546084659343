import { ReactElement, useEffect, useState } from "react";
import { AlertCircle, FolderPlus } from "react-feather";
import { Page } from "src/layouts/Page";
import { Button } from "../../components/rough/Button";
import { CourseCard } from "../../components/rough/Course";
import { CourseCardSkeleton } from "../../components/rough/Course/CourseCardSkeleton";
import { CreateCourseDialog } from "../../components/rough/Course/CreateCourseDialog";
import { getMeFromLocalStorage } from "../../data/auth/getMe";
import { useCourses } from "../../data/queries/useCourses";
import { NextPageWithLayout } from "../_app";
import { useRouter } from "next/router";

type CoursePageProps = {
  me: unknown & { clinicId: string };
};

const CoursesPage: NextPageWithLayout<CoursePageProps> = () => {
  const loggedUser = getMeFromLocalStorage();
  const { data, loading, error } = useCourses(loggedUser?.clinicId?.toString());
  const courseItems = data?.courses.data;
  const [isCreateCourseDialogOpen, setIsCreateCourseDialogOpen] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (!loggedUser) {
      router.push("/login");
    }
  }, [loggedUser, router]);

  return (
    <>
      <div className={"mx-auto flex w-full max-w-[80rem] flex-col px-24 py-8"}>
        <div className={"flex h-12 items-center justify-between"}>
          <h1 className="leading-0 text-3xl">Courses</h1>

          <Button
            variant="primary"
            text="Create course"
            onClick={() => setIsCreateCourseDialogOpen(true)}
          />
        </div>

        {loading ? (
          <div className={"flex flex-col gap-2 py-4"}>
            <CourseCardSkeleton />
            <CourseCardSkeleton />
            <CourseCardSkeleton />
          </div>
        ) : (
          <>
            {error ? (
              <div
                className={
                  "mt-12 flex h-60 flex-col items-center justify-center py-4 text-gray-500"
                }>
                <AlertCircle size="36" strokeWidth="1.25" />
                <p className={"mt-2 text-sm text-gray-600"}>We had an unexpected error</p>
                <p className={"mt-2 text-sm"}>{error?.message}</p>
              </div>
            ) : (
              <>
                {courseItems && courseItems.length > 0 ? (
                  <div className={"flex flex-col gap-2 py-4"}>
                    {courseItems.map((course) => (
                      <CourseCard key={course.id} course={course} />
                    ))}
                  </div>
                ) : (
                  <div
                    className={
                      "mt-12 flex h-60 flex-col items-center justify-center py-4 text-gray-500"
                    }>
                    <FolderPlus strokeWidth="1.25" size="36" />
                    <p className={"mt-2 text-sm"}>Get started by creating a new course.</p>
                    <div className={"mt-4"}>
                      <Button
                        variant="primary"
                        text="Create course"
                        onClick={() => setIsCreateCourseDialogOpen(true)}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      <CreateCourseDialog
        open={isCreateCourseDialogOpen}
        onOpenChange={setIsCreateCourseDialogOpen}
      />
    </>
  );
};

CoursesPage.getLayout = function getLayout(page: ReactElement) {
  return <Page>{page}</Page>;
};

export default CoursesPage;
